import React from 'react'
import { graphql } from 'gatsby'
import Layout from '../components/layout/layout.js'
import PostList from '../components/parts/postList.js'
import SEO from '../components/parts/seo.js'
import Pagination from '../components/parts/pagination.js'

const Archive = ({ path, pageContext, data }) => {
  return (
    <React.Fragment>
      {
        pageContext.currentPage === 1 ? <SEO /> : <SEO
          pathname={path}
          title={'ページ' + path.replace(/\//g, '').replace('page', '')}
          article
        />
      }
      <Layout sidebar pickup pageTitle={pageContext.currentPage === 1 ? { type: 'index' } : ''}>
        <PostList posts={data.allMdx.edges} col="2" />
        <Pagination archiveType="archive" currentPage={pageContext.currentPage} numPages={pageContext.numPages} />
      </Layout>
    </React.Fragment>
  )
}

export default Archive;

export const blogListQuery = graphql`
  query($skip: Int!, $limit: Int!){
    allMdx(
      sort: { order: DESC, fields: [frontmatter___date] }
      limit: $limit
      skip: $skip
    ) {
      edges {
        node {
          id
          frontmatter {
            date
            modified
            path
            title
            categories
            featuredImage {
              childImageSharp {
                fluid(maxWidth: 678) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        }
      }
    }
  }
`
